<template>
  <div
    class="bg-white border border-primary border-2 p-4 position-relative"
    :class="$style[`min-height-100`]"
  >
    <!--                             <div v-if="bonusAdd.length==0" class="h4">尚無購物金入帳資訊</div> -->
    <div
      v-for="(row,index) in bonusAddedList"
      :key="index"
      class="my-2"
    >
      <div
        class="bg-primary text-white d-flex justify-content-between p-2 py-md-3 px-md-4 rounded"
        data-toggle="collapse"
        :href="'#ca' + index"
        role="button"
        aria-expanded="false"
        :aria-controls="index"
      >
        <span><span class="d-none d-lg-inline me-2">銷售編號：</span>{{ row.saleID }}</span>
        <span class="d-none d-lg-inline"><span>銷售日：</span>{{ row.saleDate ?? '-' }}</span>
        <span class="d-inline d-lg-none">{{ row.saleDate ? row.saleDate.split(' ')[0] : '-' }}</span>
        <span>贈送全商品：{{ row.couponAdd ?? 0 }}</span>
        <span>贈送配件商品：{{ row.couponPartsAdd ?? 0 }}</span>
      </div>
      <div
        :id="'ca' + index"
        class="w-100 bg-white collapse border"
      >
        <div class="container-lg">
          <div class="row align-items-center my-lg-2 d-none d-lg-flex">
            <div class="col-6">
              商品說明
            </div>
            <!--                                             <div class="text-center col-lg-2">單價</div> -->
            <div class="text-center col-lg-2">
              數量
            </div>
            <!--                                             <div class="text-end col-lg-2">總價</div> -->
          </div>
          <div class="row align-items-center">
            <div class="col-6">
              {{ row.description ?? '-' }}
            </div>
            <!--                                             <div class="text-center col-2 col-lg-2 my-3 d-none d-lg-block">{{coupon.price ?? 0}}</div> -->
            <div class="text-center col-2 col-lg-2 my-3">
              {{ row.qty ?? 0 }}
            </div>
            <!--                                             <div class="text-end col-4 col-lg-2 my-3">{{coupon.price*coupon.qty ?? 0}}</div> -->
          </div>
          <!--    <hr>
                                        <div class="row my-4">
                                            <span class="col-8 ">總金額</span>
                                            <span class="col-4 text-end">{{order.originalPrice}}</span>
                                        </div>
                                        <div v-if="order.coupon"
                                             class="row my-4">
                                            <span class="col-8">使用折扣碼
                                                {{order.coupon.name}}</span>
                                            <span class="col-4 text-end text-danger">-
                                                {{order.coupon.price}}</span>
                                        </div>
                                        <div v-if="order.usedPoints"
                                             class="row my-4">
                                            <span class="col-8">使用購物金折抵</span>
                                            <span class="col-4 text-end text-danger">-
                                                {{order.usedPoints}}</span>
                                        </div>
                                        <div v-if="order.discount"
                                             class="row my-4">
                                            <span class="col-8">活動折抵
                                                {{order.discount.name}}</span>
                                            <span class="col-4 text-end text-danger">-
                                                {{order.discount.price}}</span>
                                        </div>
                                        <hr>
                                        <div class="row my-4">
                                            <span class="col-8">總金額</span>
                                            <span class="col-4 text-end">{{order.finalPrice}}</span>
                                        </div>
                                        -->
        </div>
      </div>
    </div>

    <!-- empty msg -->
    <div
      v-if="bonusAddedList.length===0"
      class="position-absolute top-50 start-50 translate-middle d-flex flex-column"
    >
      <span class="d-block mb-2">
        您還未獲得購物金</span>
      <router-link
        to="/product/list"
        class="btn btn-primary text-white rounded-0"
      >
        馬上去逛逛
      </router-link>
    </div>

    <!-- page nav -->
    <PagePanigation
      v-if="!isLoading && bonusAddedList.length!==0"
      class="d-none d-md-flex"
      :current-page="page?.current"
      :total-page="page?.totalPage"
      @setPage="setPage"
    />
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, inject, ref, reactive } from 'vue'
import PagePanigation from '@/components/PagePanigation'
export default {
  name: 'MemberBonusAdded',
  components: {
    PagePanigation
  },
  async setup (props) {
    const { getters, dispatch } = useStore()

    const page = reactive({
      current: 1,
      totalPage: 1,
      totalElement: 0
    })
    const pageSize = ref(20)

    const isLoading = computed(() => getters.getIsLoading)
    const memberID = computed(() => getters['member/getMemberID'])
    const bonusAddedList = computed(() => getters['member/getBonusAddedList'])

    const setAlert = inject('setAlert')

    const setPage = (page) => { readBonusAddedList(page) }

    const readBonusAddedList = async (newPage) => {
      const payload = {
        memberID: memberID.value,
        page: newPage || page.current,
        offset: page.current * pageSize.value
      }

      try {
        const response = await dispatch('member/readBonusAddedList', { payload })
        const data = response.data
        page.offset = data.data?.page?.offset || 0
        page.totalElement = data.data?.page?.totalElement || 0
        page.totalPage = data.data?.page?.totalPage || 1
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        page.totalElement = 0
        setAlert(true, false, error)
      }
    }

    await readBonusAddedList()

    return { bonusAddedList, isLoading, setPage, page }
  }
}
</script>
<style lang="scss" module>
.min-height-100{
  min-height: 100%;
}
</style>
